import React, { useState } from "react";
import { styled } from "styled-components";
import { color } from "../../styles/theme";
import { PiBarcodeFill } from "react-icons/pi";
import { MdMenu } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import SideBar from "./SideBar";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100vw - 32px);
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${color.bgGray};
  padding: 0 16px;
`;
const IconWrapper = styled.div`
  cursor: pointer;
  svg {
    width: ${(props) => props.width};
    height: ${(props) => props.height};
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;
const Title = styled.div`
  font-size: 16px;
  font-weight: 900;
`;

const Header = ({ title = "", onClickIcon }) => {
  const navigate = useNavigate();
  const [sideMenu, setSideMenu] = useState(null);

  const onClickDefault = () => {
    navigate("/");
  };
  const toggleSideMenu = () => {
    setSideMenu((prev) => {
      if (prev) {
        document.body.style.overflow = "unset";
      } else {
        document.body.style.overflow = "hidden";
      }
      return !prev;
    });
  };
  return (
    <React.Fragment>
      <Container>
        <TitleWrapper>
          <IconWrapper
            width="56px"
            height="56px"
            onClick={onClickIcon ? onClickIcon : onClickDefault}
          >
            <PiBarcodeFill />
          </IconWrapper>
          <Title>{title}</Title>
        </TitleWrapper>
        <IconWrapper width="32px" height="32px" onClick={toggleSideMenu}>
          <MdMenu />
        </IconWrapper>
      </Container>
      {/* <SideBar show={sideMenu} toggleShow={toggleSideMenu} /> */}
    </React.Fragment>
  );
};

export default Header;
