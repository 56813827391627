import { IoMdCloseCircle } from "react-icons/io";
import { styled } from "styled-components";
import { color } from "../../styles/theme";
import ProgressBar from "../atom/ProgressBar";
import { useEffect, useState } from "react";
import { TbHandClick } from "react-icons/tb";

const Container = styled.div`
  position: relative;
  width: 104px;
  height: 104px;
  overflow: hidden;
`;
const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const IconWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
  svg {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    color: ${color.navIcon};
    background-color: ${color.white};
  }
`;
const ProgressWrapper = styled.div`
  width: calc(100% - 16px);
  padding: 0 8px;
  position: absolute;
  left: 0;
  bottom: 4px;
`;
const ClickOverlay = styled.div`
  width: 60%;
  height: 60%;
  position: absolute;
  top: 20%;
  left: 20%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: gray;
  svg {
    color: white;
    width: 80%;
    height: 80%;
  }
`;

const ImagePreview = ({
  image = "https://source.unsplash.com/random",
  deleteFunc,
  ocrImage,
}) => {
  const [progress, setProgress] = useState(0);
  const [result, setResult] = useState("");
  useEffect(() => {
    ocrImage(image, setProgress, setResult);
  }, []);
  return (
    <Container>
      <Img src={image} />
      <IconWrapper onClick={deleteFunc}>
        <IoMdCloseCircle />
      </IconWrapper>
      <ProgressWrapper>
        <ProgressBar height="8px" percent={progress} />
      </ProgressWrapper>
      {progress === 1 && (
        <ClickOverlay onClick={() => alert(result)}>
          <TbHandClick />
        </ClickOverlay>
      )}
    </Container>
  );
};

export default ImagePreview;
