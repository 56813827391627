const _color = {
  black: "#000",
  white: "#fff",
  red: "#ff0000",
  yellow: "#f8d04b",
  bgGray: "#F2F4F6",
  typeA: "#ED1556",
  typeB: "#000000",
  typeC: "#606060",
  typeD: "#C3C3C3",
  typeE: "#E8E8E8",
  typeG: "#395EEC",
  typeH: "#595E68",
  typeI: "#c5c5c5",
  typeJ: "#2196f3",
  typeK: "#EC1515",
  navIcon: "#A1A0A1",
};

const _fontWeight = {
  Light: 100,
  Regular: 400,
  Medium: 500,
  Semibold: 600,
  Bold: 700,
  Extrabold: 800,
};

const _font = {
  txt: `font-weight: ${_fontWeight.Semibold};
  color: #000;
  font-size: 24px;
  line-height: 1.3;`,
};

const mainTheme = {
  color: _color,
  fontWeight: _fontWeight,
  font: _font,
};

export const { color, font, fontWeight } = mainTheme;
