import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "../pages/Main";
import Layout from "../components/layout/Layout";
import Header from "../components/organism/Header";

const MasterRouter = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Header title="OCR TEST PAGE" />
        <Routes>
          <Route path="/" element={<Main />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default MasterRouter;
