import { styled } from "styled-components";

const Container = styled.div`
  width: 100vw;
  height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  padding-top: 80px;
`;

const Layout = ({ children }) => {
  return <Container>{children}</Container>;
};

export default Layout;
