import { styled } from "styled-components";
import { color } from "../../styles/theme";

const Container = styled.div`
  width: 100%;
  height: ${(props) => props.height};
  background-color: ${color.bgGray};
  border: 1px solid ${color.black};
`;
const Percent = styled.div`
  width: ${(props) => props.percent + "%"};
  height: 100%;
  background-color: ${(props) => (props.done ? "green" : "red")};
`;

const ProgressBar = ({ percent = 0, height = "6px" }) => {
  percent = Math.floor(percent * 100);
  return (
    <Container height={height}>
      <Percent percent={percent} done={percent === 100} />
    </Container>
  );
};

export default ProgressBar;
