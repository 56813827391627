import { MdUploadFile } from "react-icons/md";
import { styled } from "styled-components";
import ImagePreview from "../molecule/ImagePreview";
import { useEffect, useRef, useState } from "react";

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const Label = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dedede;
  padding: 24px 0;
  border-radius: 8px;
  gap: 16px;
  cursor: pointer;
  :hover {
    background-color: #c8c8c8;
  }
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;
const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  svg {
    width: 100%;
    height: 100%;
  }
`;
const FileInput = styled.input`
  opacity: 0;
`;
const PreviewContainer = styled.div`
  width: 100%;
`;
const PreviewWrapper = styled.div`
  margin-top: 20px;
  border: 1px solid #b8b8b8;
  display: flex;
  flex-wrap: wrap;
  padding: 6px;
  gap: 4px;
`;
const Text = styled.div`
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color};
`;
const DragDropBox = ({ max = 12, ocrImage }) => {
  const [uploadImages, setUploadImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const uploadBoxRef = useRef();
  const inputRef = useRef();
  const inputId = "fileInput";

  useEffect(() => {
    const uploadBox = uploadBoxRef.current;
    const input = inputRef.current;

    const handleFiles = (files) => {
      for (const file of files) {
        if (!file.type.startsWith("image/")) continue;
        const reader = new FileReader();
        reader.onloadend = (e) => {
          const result = e.target.result;
          if (result) {
            setUploadImages((prev) => [...prev, result].slice(0, max));
          }
        };
        reader.readAsDataURL(file);
      }
    };

    const changeHandler = (event) => {
      const files = event.target.files;
      handleFiles(files);
    };

    const dropHandler = (event) => {
      event.preventDefault();
      event.stopPropagation();
      const files = event.dataTransfer.files;
      handleFiles(files);
    };

    const dragOverHandler = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    uploadBox.addEventListener("drop", dropHandler);
    uploadBox.addEventListener("dragover", dragOverHandler);
    input.addEventListener("change", changeHandler);

    return () => {
      uploadBox.removeEventListener("drop", dropHandler);
      uploadBox.removeEventListener("dragover", dragOverHandler);
      input.removeEventListener("change", changeHandler);
    };
  }, [max]);

  useEffect(() => {
    const imageComponents = uploadImages.map((image, index) => {
      const isDelete = (element) => {
        return element !== image;
      };
      const deleteFunc = () => {
        setUploadImages((prev) => prev.filter(isDelete));
      };
      return (
        <ImagePreview
          key={index}
          image={image}
          deleteFunc={deleteFunc}
          ocrImage={ocrImage}
        />
      );
    });
    setPreviewImages(imageComponents);
  }, [uploadImages]);

  return (
    <Container>
      <Label ref={uploadBoxRef} htmlFor={inputId}>
        <TextWrapper>
          <Text fontSize={"16px"}>{"드래그 또는 클릭하여 업로드"}</Text>
          <Text fontSize={"14px"} color={"#6d6d6d"}>
            {`최대 ${max}장 까지 업로드 가능합니다.`}
          </Text>
        </TextWrapper>
        <IconWrapper>
          <MdUploadFile />
        </IconWrapper>
      </Label>
      <FileInput
        ref={inputRef}
        id={inputId}
        type="file"
        multiple
        accept="image/*"
      />
      {previewImages.length > 0 && (
        <PreviewContainer>
          <PreviewWrapper>{previewImages}</PreviewWrapper>
        </PreviewContainer>
      )}
    </Container>
  );
};

export default DragDropBox;
