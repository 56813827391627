import React from "react";
import { MdClose } from "react-icons/md";
import { css, styled } from "styled-components";
import { color } from "../../styles/theme";

const SideMenuBackground = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: ${(props) => (props.sideMenu ? "block" : "none")};
  background-color: ${color.bgGray};
  opacity: 0.8;
`;
const SideMenuWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${color.white};
  width: 72vw;
  height: 100vh;
  border-left: 1px solid ${color.bgGray};
  svg {
    position: absolute;
    top: 24px;
    left: 24px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  ${(props) =>
    props.sideMenu !== null &&
    css`
      animation: 0.7s ${(props) => (props.sideMenu ? "showUp" : "showOut")}
        forwards;
    `}
  @keyframes showUp {
    0% {
      transform: translate(72vw, 0);
    }

    100% {
      transform: translate(0, 0);
    }
  }

  @keyframes showOut {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(72vw, 0);
      display: none;
    }
  }
`;

// TODO
const SideBar = ({ show = null, toggleShow }) => {
  return (
    <React.Fragment>
      <SideMenuBackground sideMenu={show} onClick={toggleShow} />
      <SideMenuWrapper sideMenu={show}>
        <MdClose onClick={toggleShow} />
      </SideMenuWrapper>
    </React.Fragment>
  );
};

export default SideBar;
