import { styled } from "styled-components";
import DragDropBox from "../../components/organism/DragDropBox";
import Tesseract from "tesseract.js";

const Container = styled.div`
  padding-top: 24px;
  width: 90vw;
`;
const Main = () => {
  const ocrImage = (image, setProgress, setResult) => {
    Tesseract.recognize(image, "eng+kor", {
      logger: (m) => {
        if (m.status === "recognizing text") {
          setProgress(m?.progress);
        }
      },
    })
      .then((res) => setResult(res.data.text))
      .catch((err) => console.err(err));
  };
  return (
    <Container>
      <DragDropBox ocrImage={ocrImage} />
    </Container>
  );
};

export default Main;
